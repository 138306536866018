/* You can add global styles to this file, and also import other style files */
@import "../node_modules/@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://unpkg.com/leaflet@1.9.2/dist/leaflet.css");

* {
  font-family: "Rubik", sans-serif;
}

body {
  overflow-x: hidden;
}

select {
  border-radius: 15px !important;
}

input {
  border-radius: 15px !important;
}

/* ANIMACIONES */

#fade {
  -webkit-animation: fadein 0.3s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.3s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.3s;
  /* Internet Explorer */
  -o-animation: fadein 0.3s;
  /* Opera < 12.1 */
  animation: fadein 0.3s;
}

#fade_lg {
  -webkit-animation: fadein 0.5s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 0.5s;
  /* Firefox < 16 */
  -ms-animation: fadein 0.5s;
  /* Internet Explorer */
  -o-animation: fadein 0.5s;
  /* Opera < 12.1 */
  animation: fadein 0.5s;
}

#fade_xl {
  -webkit-animation: fadein 2s;
  /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s;
  /* Firefox < 16 */
  -ms-animation: fadein 2s;
  /* Internet Explorer */
  -o-animation: fadein 2s;
  /* Opera < 12.1 */
  animation: fadein 2s;
}

@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Firefox < 16 */
@-moz-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Internet Explorer */
@-ms-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

/* Opera < 12.1 */
@-o-keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.headertabla {
  background-color: #00277a;
  color: #fff;

  font-size: 13px;
  min-height: 30px !important;

  text-align: center !important;
  justify-content: center !important;
  align-self: center !important;
}

mat-row {
  min-height: 30px !important;
}

mat-table {
  max-height: 60vh !important;
  max-width: 96.5vw !important;
}


.space {
  border-radius: 15px !important;
  padding: 15px !important;
  padding-top: 5px !important;
}